import { Component, Input } from '@angular/core';

@Component({
  selector: 'reader-ui-info-component',
  templateUrl: './info-component.component.html',
  styleUrl: './info-component.component.scss',
})
export class InfoComponentComponent {
  @Input() isLeftAligned = false;
  isInfoVisible: boolean = false;

  handleInfoClick(event: any): void {
    event.stopPropagation();
    this.isInfoVisible = !this.isInfoVisible;
  }

  closeInfo(event: any): void {
    event.stopPropagation();
    this.isInfoVisible = false;
  }
}
