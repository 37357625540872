import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ButtonPurpose } from '@mhe/ngx-shared';
import { EpubMoveDirection } from '@mhe/reader/models';
import { TranslateService } from '@ngx-translate/core';

import { annotateSelectionOnMobile } from '../reader/state/epub-controls.actions';
import { ReaderStore } from '../reader/state/reader.store';
import { ReaderConfigStore } from '../reader/state';
import {
  EpubViewerStore,
  FixedPageService,
} from '@mhe/reader/components/epub-viewer';
import { GoogleAnalyticsService } from '@mhe/reader/features/analytics';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'reader-core-epub-controls',
  templateUrl: './epub-controls.component.html',
  styleUrls: ['./epub-controls.component.scss'],
})
export class EpubControlsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isDoubleSpread: boolean;
  @Input() isFixedLayout: boolean;
  @Input() isSinglePageViewOnly: boolean;
  @Input() albumMode: boolean;
  @Input() zoomLevel: number;
  @Input() enableUpArrow = false;
  @Input() enableDownArrow = false;
  @Input() enableLeftArrow = false;
  @Input() enableRightArrow = false;
  @Input() increaseEpubZoomEnabled: boolean;
  @Input() decreaseEpubZoomEnabled: boolean;
  @Input() enableTTS = false;
  @Input() showReadSpeakerVoicePick = false;
  @Input() isAiAssistOffered = false;

  @Output() toggleAlbumMode = new EventEmitter();
  @Output() toggleAiAssistPanel = new EventEmitter();
  @Output() increaseEpubZoomLevel = new EventEmitter();
  @Output() decreaseEpubZoomLevel = new EventEmitter();
  @Output() moveEpub = new EventEmitter<EpubMoveDirection>();

  private readonly destroy$ = new Subject<void>();
  isAlbumModeForced$ = this.epubViewerStore.isAlbumModeForced$;
  isFplSpine$ = this.epubViewerStore.isFplSpine$;
  isAiAssistOpen$ = this.readerStore.isAiAssistOpen$;

  ribac$ = this.readerConfigStore.ribac$;

  albumModeLabel = '';
  zoomDisplay = '';

  // expose import ref
  ButtonPurpose = ButtonPurpose;
  EpubMoveDirection = EpubMoveDirection;

  constructor(
    readonly readerStore: ReaderStore,
    private readonly epubViewerStore: EpubViewerStore,
    private readonly fixedPageService: FixedPageService,
    private readonly ga: GoogleAnalyticsService,
    private readonly liveAnnouncer: LiveAnnouncer,
    private readonly readerConfigStore: ReaderConfigStore,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.isAiAssistOpen$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (!value) {
        const annotationBarAiAssistButton = document.querySelector('[data-automation-id="ai-assist"]') as HTMLElement;
        if (annotationBarAiAssistButton) {
          annotationBarAiAssistButton.focus();
          return;
        }

        const aiAssistButton = document.querySelector('.aiAssistButton') as HTMLElement;
        if (aiAssistButton) {
          aiAssistButton.focus();
        }
      } else {
        const aiButtonClose = document.querySelector('.close-ai-assist') as HTMLElement;
        if (aiButtonClose) {
          setTimeout(() => {
            aiButtonClose.focus();
          }, 100);
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { albumMode, zoomLevel } = changes;

    if (albumMode) {
      const labelKey = albumMode.currentValue
        ? 'iframe.album_mode_on'
        : 'iframe.album_mode_off';
      this.albumModeLabel = this.translateService.instant(labelKey);
    }

    if (zoomLevel) {
      this.zoomDisplay = `${Math.round(zoomLevel.currentValue * 100)}%`;
    }
  }

  onToggleAlbumMode(): void {
    const activeElement = document.activeElement;

    this.toggleAlbumMode.emit();
    this.ga.event({
      eventCategory: 'Fixed Page Controls',
      eventAction: 'Page spread toggle',
    });

    this.fixedPageService.restoreActiveElementFocus(activeElement as Element);
  }

  increaseZoomLevel(): void {
    this.increaseEpubZoomLevel.emit();
    const zoomInText = this.translateService.instant('iframe.zoom_in_page');
    void this.liveAnnouncer.announce(zoomInText);

    this.ga.event({
      eventCategory: 'Fixed Page Controls',
      eventAction: 'Zoom in',
    });
  }

  decreaseZoomLevel(): void {
    this.decreaseEpubZoomLevel.emit();
    const zoomOutText = this.translateService.instant('iframe.zoom_out_page');
    void this.liveAnnouncer.announce(zoomOutText);

    this.ga.event({
      eventCategory: 'Fixed Page Controls',
      eventAction: 'Zoom out',
    });
  }

  annotateSelectionOnMobile(): void {
    this.readerStore.dispatch(annotateSelectionOnMobile());
  }

  moveEpubOnClick(direction: string): void {
    this.moveEpub.emit(EpubMoveDirection[direction.toUpperCase()]);
    void this.liveAnnouncer.announce(this.translateService.instant(`iframe.image_moved.${direction}`));
  }

  onClickAiAssist(): void {
    this.toggleAiAssistPanel.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
