/* eslint-disable @typescript-eslint/quotes */
import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { AnnotationType } from '../../annotation.data';

@Component({
  selector: 'rdrx-annotations-menu',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    // cdk
    A11yModule,
    OverlayModule,
    // material
    MatMenuModule,
  ],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnotationListMenuComponent {
  AnnotationType = AnnotationType;
  @Input({ required: true }) type!: AnnotationType;
  @Output() editModeMenuSelected = new EventEmitter<boolean>();
  @Output() exportAnnotationsSelected = new EventEmitter();

  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;

  @HostListener('keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.menuTrigger.openMenu();
    }
  }

  handleExportBtn(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.exportAnnotationsSelected.emit();
  }
}
