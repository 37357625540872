import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewChecked,
} from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';

import { TocStore } from './state/toc.store';
import { TocBonsaiNode, ROOT_BONSAI_NODE } from '@mhe/reader/models';
import { BonsaiTreeComponent } from '@mhe/ngx-bonsai-reader';
import {
  setParentListAriaLabel,
  setAriaCurrentElement,
} from '@mhe/reader/utils';

@Component({
  selector: 'reader-core-toc',
  templateUrl: './toc.component.html',
  styleUrls: ['./toc.component.scss'],
})
export class TocComponent implements AfterViewChecked {
  @ViewChild(BonsaiTreeComponent, { read: ElementRef }) tree: ElementRef;

  readonly rootNodeId = ROOT_BONSAI_NODE;

  readonly activeId$ = this.tocStore.activeBonsaiNodeId$.pipe(shareReplay());
  readonly expandedNodes$ = this.tocStore.expandedNodes$;
  readonly showBonsai$ = this.tocStore.tree$.pipe(
    map((tree) => !!tree && !!tree[this.rootNodeId]),
  );

  readonly tree$ = this.tocStore.tree$;

  constructor(private readonly tocStore: TocStore) {}

  ngAfterViewChecked(): void {
    this.setAriaCurrentElement();
    setParentListAriaLabel(this.tree, 'Table of Contents');
    for (const node of this.tree.nativeElement.children[0].children) {
      if (node instanceof HTMLElement && node.hasAttribute('role')) {
        node.removeAttribute('role');
      }
    }
  }

  toggleNodeExpanded(nodeId: string): void {
    this.tocStore.toggleExpandedNode(nodeId);
  }

  handleNodeClick(node: TocBonsaiNode): void {
    this.tocStore.nodeClick$(node);
  }

  isCollapsible(node: TocBonsaiNode): boolean {
    return node.collapsible ?? true;
  }

  focusActiveNode($event: Event): void {
    $event.preventDefault();

    const { tree } = this;
    const activeEl = tree.nativeElement.querySelector('.node-active');
    activeEl?.focus();
  }

  private setAriaCurrentElement(): void {
    this.activeId$.subscribe((id) => {
      setAriaCurrentElement(id, this.tree);
    });
  }
}
