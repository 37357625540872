<ng-container *ngIf="showOverflowMenu$ | async">
  <button
    data-automation-id="overflow-menu"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    aria-haspopup="true"
    [title]="menuLabel$ | async"
    class="btn btn-icon kebab-button"
    (click)="openMenu()"
  >
    <span class="kebab-menu-icon"></span>
    <!-- TODO remove later: This icon uses the ngx-shared 9 and it's breaking the UI -->
    <!-- <i class="dpg-icon dpg-icon-system-kebab"></i> -->
  </button>

  <!-- menu overlay -->
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayPositions]="connectionPositions"
    [cdkConnectedOverlayOffsetY]="5"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="rdrxMeneBackDropClass"
    [cdkConnectedOverlayOpen]="menuOpen$ | async"
    (backdropClick)="menuClickOutside($event)"
    (attach)="onAttach()"
    (detach)="onDetach()"
  >
    <div class="rdrx-menu" data-automation-id="menu-container">
      <div cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
        <!-- teacher options -->
        <ng-container *ngIf="teacherOptions$ | async as teacherOptions">
          <div class="grouped-list">
            <ul role="menu" (keydown)="teacherOptionsArrowKeyNav($event)">
              <ng-container *ngFor="let option of teacherOptions; trackBy: trackByOptionValue">
                <li role="presentation">
                  <button
                    class="rdrx-menu-item"
                    [id]="option?.value"
                    [rdrxMenuItem]="option"
                    [attr.aria-expanded]="option.active"
                    (click)="optionSelected(option)"
                  >
                    {{ option.viewValue | translate }}
                  </button>
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-container>

        <!-- leveled content -->
        <ng-container *ngIf="contentLevelOptions$ | async as levelOptions">
          <fieldset *ngIf="levelOptions.length" class="grouped-list with-label">
            <legend class="group-label">
              {{ 'levelText.level_selection' | translate | titlecase }}
            </legend>

            <ul
              role="menu"
              tabindex="0"
              (keydown)="levelContentKeyDown($event)"
              (focus)="levelOptionsFocus($event)"
            >
              <ng-container *ngFor="let level of levelOptions; trackBy: trackByOptionValue">
                <li>
                  <button
                    class="rdrx-menu-item"
                    role="menuitemradio"
                    tabindex="-1"
                    [rdrxMenuItem]="level"
                    [attr.aria-checked]="level.active"
                    (click)="contentLevelSelected(level.value)"
                  >
                    {{ level.viewValue }}
                  </button>
                </li>
              </ng-container>
            </ul>
          </fieldset>
        </ng-container>

        <!-- lexile levels -->
        <ng-container *ngIf="lexileLevelOptions$ | async as levelOptions">
          <fieldset *ngIf="levelOptions.length" class="grouped-list with-label">
            <legend class="group-label">
              Lexile {{ 'levelText.level_selection' | translate | titlecase }}
            </legend>

            <ul
              role="menu"
              tabindex="0"
              (keydown)="levelContentKeyDown($event)"
              (focus)="levelOptionsFocus($event)"
            >
              <ng-container *ngFor="let level of levelOptions; trackBy: trackByOptionValue">
                <li>
                  <button
                    class="rdrx-menu-item"
                    role="menuitemradio"
                    tabindex="-1"
                    [rdrxMenuItem]="level"
                    [attr.aria-checked]="level.active"
                    (click)="lexileLevelSelected(level.value)"
                  >
                    {{ level.viewValue }}
                  </button>
                </li>
              </ng-container>
            </ul>
          </fieldset>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>
