import { Injectable } from '@angular/core';
import { AnnotationData } from '@mhe/reader/models';
import { EpubLibCFIService } from './epub-lib/epub-lib-cfi.service';
import { EpubLibHighlighterService } from './epub-lib/epub-lib-highlighter.service';
import { EpubLibService } from './epub-lib/epub-lib.service';

@Injectable()
export class HighlighterService {
  constructor(
    protected highlighterLib: EpubLibHighlighterService,
    protected cfiLib: EpubLibCFIService,
    protected epubLibService: EpubLibService,
  ) {}

  addAnnotation(
    annotation: AnnotationData,
    doc: Document,
    nonTextFlag: boolean = false,
  ): void {
    this.highlighterLib.setBaseCFI(this.cfiLib.getBasePath(annotation.cfi));
    this.highlighterLib.addMarksForAnnotation(
      annotation,
      0,
      doc,
      this.cfiLib.getCfiLibraryVersion() as string,
      nonTextFlag,
    );
    if (!annotation.text) {
      annotation.text = this.cfiLib.getTextFromCFI(annotation.cfi, doc);
    }

    const annotationElements = doc.querySelectorAll('.annotation');
    annotationElements?.forEach((annotationElement) => {
      annotationElement.setAttribute('aria-label', annotationElement.innerHTML);
    });
  }

  removeAnnotation(
    annotation: AnnotationData,
    doc: Document,
    nonTextFlag: boolean = false,
  ): void {
    this.highlighterLib.setBaseCFI(this.cfiLib.getBasePath(annotation.cfi));
    this.highlighterLib.removeMarksForAnnotation(doc, annotation, nonTextFlag);
  }

  getAnnotationEl(annotation: AnnotationData, doc: Document): Element {
    return doc.querySelector(
      '[data-highlight-id="' + annotation.id + '"]',
    ) as Element;
  }

  setCfiLibraryVersion(version: 'v5' | 'v6'): void {
    this.cfiLib.setCfiLibraryVersion(version);
  }

  createFromSelection(
    doc: Document,
    cfiBase: string,
    optionalLegacyVersion?,
    nonTextFlag?,
  ): { cfi: string, text: string } {
    const annotation = this.epubLibService.createFromSelection(
      doc,
      cfiBase,
      optionalLegacyVersion,
      nonTextFlag,
    );
    if (annotation && !annotation.text && !nonTextFlag) {
      annotation.text = this.cfiLib.getTextFromCFI(annotation.cfi, doc);
    }
    return annotation;
  }
}
