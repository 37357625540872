import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxBonsaiModule } from '@mhe/ngx-bonsai-reader';
import { ButtonModule, InputModule } from '@mhe/ngx-shared';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { SearchResultPipe } from './search-result.pipe';
import { SearchPageNumberResultPipe } from './search-page-number.pipe';
import { SearchComponent } from './search.component';
import { CommonDirectivesModule } from '@mhe/reader/common';
import { A11yModule } from '@angular/cdk/a11y';
import { InfoComponentModule } from '../info-component/info-component.module';

@NgModule({
  imports: [
    CommonModule,
    InfoComponentModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    TranslateModule,
    ButtonModule,
    TooltipModule,
    InputModule,
    NgxBonsaiModule,
    CommonDirectivesModule,
    A11yModule,
  ],
  declarations: [SearchComponent, SearchResultPipe, SearchPageNumberResultPipe],
  exports: [SearchComponent],
  providers: [TranslatePipe],
})
export class SearchModule {}
