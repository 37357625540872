<div [ngSwitch]="type">
  <ng-template [ngSwitchCase]="AnnotationType.HIGHLIGHT">
    <button
      #menuButton
      mat-button
      [matMenuTriggerFor]="beforeMenu"
      aria-label="Options"
      class="btn btn-icon kebab-button"
      tabindex="0"
    >
      <span class="kebab-menu-icon"></span>
    </button>
    <mat-menu
      class="contents-dropdown"
      #beforeMenu="matMenu"
      xPosition="before"
    >
      <button mat-menu-item (click)="editModeMenuSelected.emit(true)">
        {{ "contents.menu_edit_highlights" | translate }}
      </button>
      <!-- // TODO move the whole functionality -->
      <button mat-menu-item (click)="handleExportBtn($event)">
        {{ "contents.menu_export_highlights" | translate }}
      </button>
    </mat-menu>
  </ng-template>

  <ng-template [ngSwitchCase]="AnnotationType.NOTE">
    <button
      mat-button
      [matMenuTriggerFor]="beforeMenu"
      aria-label="Options"
      class="btn btn-icon kebab-button"
    >
      <span class="kebab-menu-icon"></span>
    </button>
    <mat-menu
      class="contents-dropdown"
      #beforeMenu="matMenu"
      xPosition="before"
    >
      <button mat-menu-item (click)="editModeMenuSelected.emit(true)">
        {{ "contents.menu_edit_notes" | translate }}
      </button>
      <!-- // TODO move the whole functionality -->
      <button mat-menu-item (click)="handleExportBtn($event)">
        {{ "contents.menu_export_notes" | translate }}
      </button>
    </mat-menu>
  </ng-template>

  <ng-template [ngSwitchCase]="AnnotationType.PLACEMARK">
    <button
      mat-button
      [matMenuTriggerFor]="beforeMenu"
      aria-label="Options"
      class="btn btn-icon kebab-button"
    >
      <span class="kebab-menu-icon"></span>
    </button>
    <mat-menu
      class="contents-dropdown"
      #beforeMenu="matMenu"
      xPosition="before"
    >
      <button mat-menu-item (click)="editModeMenuSelected.emit(true)">
        {{ "contents.menu_edit_placemarks" | translate }}
      </button>
    </mat-menu>
  </ng-template>
</div>
