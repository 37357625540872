<div role="dialog" [attr.aria-label]="'annotation.annotation_toolbar' | translate" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <div class="action-container" role="toolbar" *ngIf="!onlyReadspeakerEnabled">
    <div class="underline-container" *ngIf="config.highlights">
      <button
        mhe-button
        [purpose]="ButtonPurpose.Icon"
        class="action"
        id="add-annotation-highlight"
        data-automation-id="add-annotation-highlight"
        (click)="highlight('default')"
        [mheTooltip]="'context.add_highlight' | translate"
        [attr.aria-pressed]="isHighlightPressed('default') | async"
      >
        <i class="dpg-icon dpg-icon-highlighter"></i>
      </button>
      <div [ngClass]="defaultClass$ | async"></div>
    </div>

    <div class="underline-container" *ngIf="config.highlights">
      <button
        mhe-button
        [purpose]="ButtonPurpose.Icon"
        class="action"
        data-automation-id="add-annotation-underline"
        (click)="highlight('underline')"
        [mheTooltip]="'context.add_underline' | translate"
        [attr.aria-pressed]="isHighlightPressed('underline') | async"
      >
        <i class="dpg-icon dpg-icon-keyboard-letters-underline"></i>
      </button>
      <div [ngClass]="underlineClass$ | async"></div>
    </div>

    <div class="underline-container" *ngIf="config.highlights">
      <button
        mhe-button
        [purpose]="ButtonPurpose.Icon"
        class="action"
        data-automation-id="add-annotation-box"
        (click)="highlight('box')"
        [mheTooltip]="'context.add_box' | translate"
        [attr.aria-pressed]="isHighlightPressed('box') | async"
      >
        <i class="dpg-icon dpg-icon-keyboard-letters-rectangle"></i>
      </button>
      <div [ngClass]="boxClass$ | async"></div>
    </div>

    <div class="underline-container" *ngIf="config.highlights">
      <button
        mhe-button
        [purpose]="ButtonPurpose.Icon"
        class="action"
        data-automation-id="add-annotation-circle"
        (click)="highlight('circle')"
        [mheTooltip]="'context.add_circle' | translate"
        [attr.aria-pressed]="isHighlightPressed('circle') | async"
      >
        <i class="dpg-icon dpg-icon-keyboard-letters-rectangle-rounded"></i>
      </button>
      <div [ngClass]="circleClass$ | async"></div>
    </div>

    <div class="underline-container" *ngIf="config.placemarks">
      <button
        mhe-button
        [purpose]="ButtonPurpose.Icon"
        class="action"
        data-automation-id="placemark-toggle"
        (click)="placemark()"
        [mheTooltip]="
          (store.placemarkText$ | async)?.length > 0
            ? ('context.remove_placemark' | translate)
            : ('context.add_placemark' | translate)"
        [attr.aria-pressed]="isPlacemarkPressed() | async"
      >
        <i class="ahe-icon dpg-icon-bookmark"></i>
      </button>
      <div [class.underline-container-default]="(store.placemarkText$ | async)?.length > 0"></div>
    </div>

    <div class="underline-container" *ngIf="config.notes">
      <button
        mhe-button
        [purpose]="ButtonPurpose.Icon"
        class="action"
        data-automation-id="add-note"
        (click)="store.showOptionsPane('note')"
        [mheTooltip]="
          (store.note$ | async)?.length > 0
            ? ('context.edit_note' | translate)
            : ('context.add_note' | translate)
        "
        [attr.aria-pressed]="(store.visibleOptionsPane$ | async) === 'note'"
      >
        <i class="ahe-icon dpg-icon-notepad"></i>
      </button>
      <div
        [class.underline-container-default]="
          (store.note$ | async)?.length > 0 || (store.visibleOptionsPane$ | async) === 'note'
        "
      ></div>
    </div>

    <div class="readspeaker-icon" *ngIf="config.readspeaker">
      <button
        mhe-button
        [purpose]="ButtonPurpose.Icon"
        class="action"
        data-automation-id="read-selected-text"
        (click)="onLoadAudioText()"
        [mheTooltip]="'readspeaker.read_selected' | translate"
        [attr.aria-pressed]="isReadspeakerPressed() | async"
      >
        <i class="dpg-icon dpg-icon-audioon"></i>
      </button>
    </div>

    <div class="underline-container ai-assist" *ngIf="config.isAiAssistOffered" >
      <button
        mhe-button
        [purpose]="ButtonPurpose.Icon"
        class="action"
        data-automation-id="ai-assist"
        (click)="onClickAiAssist()"
        [mheTooltip]="'ai_assist.button' | translate"
        [attr.aria-pressed]="isAiAssistPressed() | async"
      >
        <i class="ai-assist ai-assist-icon"></i>
      </button>
      <span class="badge">BETA</span>
    </div>

    <button
      mhe-button
      [purpose]="ButtonPurpose.Icon"
      class="action"
      data-automation-id="context-menu-close"
      (click)="close()"
      [mheTooltip]="'navigation.close' | translate"
      cdkFocusInitial
    >
      <i class="dpg-icon dpg-icon-system-close"></i>
    </button>
  </div>

  <div class="highlight-selection-pane" *ngIf="(store.visibleOptionsPane$ | async) === 'color'">
    <div class="highlight-selection-pane__colors">
      <ng-container *ngFor="let colorOptionWithTooltip of colorOptionsWithTooltip$ | async">
        <button
          mhe-button
          [purpose]="ButtonPurpose.Icon"
          class="color-button"
          (click)="store.setHighlightColor(colorOptionWithTooltip.highlightColor)"
          [mheTooltip]="colorOptionWithTooltip.tooltip"
          [attr.aria-pressed]="(store.highlightColor$ | async) === colorOptionWithTooltip.highlightColor"
        >
          <rdrx-color-icon
            [id]="colorOptionWithTooltip.highlightColor+'-icon'"
            [color]="colorOptionWithTooltip.highlightColor"
            [selected]="(store.highlightColor$ | async) === colorOptionWithTooltip.highlightColor"
          ></rdrx-color-icon>
        </button>
      </ng-container>
    </div>
    <div *ngIf="!isTouchScreen" class="highlight-selection-pane__quick-annotation">
      <rdrx-quick-annotation></rdrx-quick-annotation>
    </div>
  </div>


  <div
    [class.tts-audio-controls-pane]="!onlyReadspeakerEnabled"
    *ngIf="(store.visibleOptionsPane$ | async) === 'readspeaker'"
  >
    <rdrx-audio-controls
      [audioContext]="TTSAudioContext.HIGHLIGHT"
      [showClose]="onlyReadspeakerEnabled"
      (closeEvent)="close()"
    >
    </rdrx-audio-controls>
  </div>

  <div class="note-pane" *ngIf="(store.visibleOptionsPane$ | async) === 'note'">
    <mhe-textarea
      #mheTextArea
      class="note-input"
      data-automation-id="note-input"
      [value]="store.note$ | async"
      (inputChange)="noteChanged($event)"
      [maxlength]="store.noteMaxCharacters$ | async"
      [rows]="undefined"
    ></mhe-textarea>
    <div class="character-counter">
      {{ (store.note$ | async)?.length || '0' }} / {{ store.noteMaxCharacters$ | async }}
    </div>
  </div>
</div>
