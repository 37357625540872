<div class="annotation-list">
  <rdrx-annotation-list-header
    [headerClass]="(emptyTree$ | async) || (sessionEnded$ | async)"
    [type]="headerType"
    [isEditMode]="isEditMode"
    [areAllAnnotationsSelected]="allAnnotationsSelected"
    (dropdownOptionSelected)="handleDropdownState($event)"
    (editModeSelected)="handleEditMode($event)"
    (allAnnotationsSelected)="handleAllAnnotationsSelected($event)"
    (exportAnnotationsSelected)="beginExport()"
  ></rdrx-annotation-list-header>

  <div class="content">
    <p *ngIf="emptyTree$ | async" class="list-empty-msg" translate>contents.note_blank</p>
    <mhe-alert *ngIf="sessionEnded$ | async" [smallAlert]="true" [type]="AlertType.Warning" [closable]="false">
      {{ 'contents.note_session_error' | translate }}
    </mhe-alert>
    <div class="export-alert" *ngIf="exportStatus$ | async">
      <mhe-alert [smallAlert]="true" [type]="AlertType.Info" [closable]="true">
        {{ 'export.in_progress' | translate }}
      </mhe-alert>
    </div>

    <ngx-bonsai
      class="rdrx"
      data-automation-id="notes-group"
      rdrxBonsaiTabIndex
      [nodesById]="nodeTree$ | async | orphanAnnotationsFilter:notesDropDownState()"
      [rootNodeId]="rootNodeId"
      [expandedNodes]="noteListStore.expandedNodes$ | async"
      [showToggleFn]="isCollapsible"
      [isLoading]="isLoading"
      (expanderClick)="toggleNodeExpanded($event)"
    >
      <ng-template #treeNodeTemplate let-node>
        <div
          mheStopPropagation
          rdrxBonsaiNodeSelect
          class="annotation-item"
          data-automation-id="notes-children"
          [rdrxBonsaiNodeSelectKeyboard]="!node.collapsible"
          (click)="handleNodeClick(node)"
        >
          <mhe-checkbox
            *ngIf="isEditMode && !node.collapsible"
            id="check-annotation"
            [checked]="isNodeChecked(node)"
            (change)="handleCheckAnnotation(node)"
          >&nbsp;</mhe-checkbox>

          <div
            *ngIf="node.annotation"
            [ngClass]="{'image': !isEditMode }"
          >
            <div *ngIf="node.annotation.orphan">
              <img
                id='unlink-icon'
                src="/assets/icons/reader/unlink.svg"
                alt="Unlink Icon"
                width="30px"
                [mheTooltip]="'context.detached_highlight' | translate"
                [placement]="'right'">
            </div>

          </div>
          <div class="title">
            <p
              *ngIf="node.annotation?.orphan"
              class="orphan-highlight-msg"
            >
              {{ 'contents.orphan_notes_msg' | translate }}
            </p>
            <span class="date" *ngIf="node.annotation?.updatedAt">
              {{ node.annotation?.updatedAt | date : 'shortDate' }}
            </span>
            <span id="expandable-note-{{ node.id }}" (text)="handleTextChange($event, node)" rdrxTruncate>{{ node.title }}</span>
          </div>
        </div>
        <button
          *ngIf="!node.collapsible"
          aria-label="button"
          mhe-button
          [purpose]="ButtonPurpose.Icon"
          class="trashcan"
          data-automation-id="remove-note"
          [disabled]="noteListStore.readonly$ | async"
          [mheStopPropagation]="['click', 'keydown.Enter', 'keydown.Space']"
          rdrxHideOnEscape
          [mheTooltip]="'context.remove_note' | translate"
          [alternateAriaLabel]="('context.remove_note' | translate) + ' ' + node.ariaLabel"
          (click)="delete(node.annotation)"
        >
          <i class="dpg-icon dpg-icon-trash"></i>
        </button>
      </ng-template>
      <ng-template #loadingTemplate>
        <mat-progress-spinner
          class="rdrx-spinner spine-annotations-loading"
          mode="indeterminate"
          [strokeWidth]="2"
          [diameter]="75"
        ></mat-progress-spinner>
      </ng-template>
    </ngx-bonsai>
  </div>
  <rdrx-annotation-list-footer
    *ngIf="isEditMode"
    class="rdrx-annotation-list-footer"
    [disableDeleteBtn]="!isFooterBtnDisabled"
    (deleteClick)="handleDeleteClick()"
    (cancelClick)="handleCancelClick()"
  ></rdrx-annotation-list-footer>
</div>
