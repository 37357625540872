import {
  AfterViewInit,
  Directive,
  DoCheck,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[rdrxGlossaryDefinition]',
})
export class GlossaryDefinitionDirective implements OnChanges, AfterViewInit, DoCheck {
  @Input() glossaryMultilang;
  @Input() currentLang;

  changesHappened = false;

  private readonly elem: HTMLDivElement;
  private term: HTMLDivElement;
  private definition: HTMLDivElement;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    this.elem = elementRef.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.changesHappened = true;
  }

  ngDoCheck(): void {
    if (this.changesHappened) this.updateValues();
  }

  ngAfterViewInit(): void {
    this.term = this.elem.getElementsByClassName('glossary-term')[0] as HTMLDivElement;
    this.definition = this.elem.getElementsByClassName('glossary-definition')[0] as HTMLDivElement;

    if (this.term && this.definition) {
      this.updateValues();
    }
  }

  updateValues(): void {
    const headword = this.elem.getElementsByClassName('glossary-headword');
    if (!(headword?.length > 0)) return;

    if (!this.glossaryMultilang) {
      this.deleteHeadword(headword, true);
    } else {
      this.updateHeadwordTerm(headword[0] as HTMLDivElement);
      this.deleteHeadword(headword, false);
    }

    this.changesHappened = false;
  }

  updateHeadwordTerm(headword: HTMLDivElement): void {
    if (this.term.firstChild) this.renderer.removeChild(this.term, this.term.firstChild);
    const headwordText = headword.textContent as string;
    const newSubtitle: HTMLHeadingElement = this.renderer.createElement('h3');
    this.renderer.setStyle(newSubtitle, 'display', 'inline');
    newSubtitle.textContent = headwordText;
    this.renderer.appendChild(this.term, newSubtitle);
  }

  deleteHeadword(headword: HTMLCollectionOf<Element>, deep: boolean): void {
    const parentNode = headword[0].parentNode as ParentNode;
    // If the glossary definition only have 1 child, then remove the whole headword
    if (headword[0].parentNode?.children.length === 1) {
      this.renderer.removeChild(this.definition, headword[0].parentNode);
      return;
    }
    this.renderer.removeChild(
      deep ? this.elem : parentNode,
      deep ? parentNode : headword[0],
    );
  }
}
