import { FocusKeyManager, LiveAnnouncer } from '@angular/cdk/a11y';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ButtonPurpose } from '@mhe/ngx-shared';
import * as widgetsActions from '@mhe/reader/global-store/widgets/widgets.actions';
import {
  LexileLevel,
  LexileLevelsOption,
  isLexileLevel,
} from '@mhe/reader/models';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  asyncScheduler,
  combineLatest,
  Subject,
  Observable,
} from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { ReaderConfigStore, ReaderStore } from '../../reader/state';
import * as readerActions from '../../reader/state/reader.actions';
import * as toolbarActions from '../../reader/state/toolbar.actions';
import {
  OverflowMenuActions,
  isOverflowMenuAction,
} from '../overflow-menu-actions.type';
import { MenuItemDirective } from './overflow-menu-item.directive';
import { OverflowMenuOption } from './overflow-menu-option.model';
import { AllowedLevel, isAllowedLevel } from '@mhe/reader/types';

@Component({
  selector: 'rdrx-overflow-menu',
  templateUrl: './overflow-menu.component.html',
  styleUrls: ['./overflow-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverflowMenuComponent implements OnDestroy {
  /** focus manager */
  @ViewChildren(MenuItemDirective) items: QueryList<MenuItemDirective>;
  teacherOptionsKeyManager: FocusKeyManager<MenuItemDirective>;
  levelContentKeyManager: FocusKeyManager<MenuItemDirective>;
  readonly teacherContent = 'teacher-content';
  readonly clearWidgetDataAll = 'clear-widget-data-all';
  readonly clearWidgetDataPg = 'clear-widget-data-pg';

  /** menu state */
  readonly menuOpen = new BehaviorSubject<boolean>(false);
  readonly menuOpen$ = this.menuOpen.asObservable();

  /** cdk:overlay config */
  readonly connectionPositions = [
    new ConnectionPositionPair(
      { originX: 'end', originY: 'bottom' },
      { overlayX: 'end', overlayY: 'top' },
    ),
  ];

  readonly rdrxMeneBackDropClass = 'rdrx-menu-backdrop';

  /** config state */
  readonly review$ = this.config.assignment$.pipe(map((a) => a === 'review'));
  readonly isTeacher$ = this.config.isTeacher$;
  readonly isTeacherAndReview$ = combineLatest([
    this.config.isTeacher$,
    this.review$,
  ]).pipe(map(([teacher, review]) => teacher && review));

  /** options state */
  readonly teacherContentEnabled$ = this.readerStore.teacherContentEnbled$;

  /** options */
  readonly teacherOptions$ = this.getTeacherOptions$();
  readonly contentLevelOptions$ = this.getContentLevelOtpions$();
  readonly lexileLevelOptions$ = this.getLexileLevelOptions$();

  readonly overflowMenuConfig$ = combineLatest([
    this.config.overflow$,
    this.isTeacher$,
    this.contentLevelOptions$,
    this.lexileLevelOptions$,
  ]).pipe(shareReplay());

  readonly showOverflowMenu$ = this.overflowMenuConfig$.pipe(
    map(
      ([overflow, teacher, leveledOptions, lexileOptions]) =>
        overflow && (teacher || leveledOptions?.length || lexileOptions?.length),
    ),
  );

  readonly menuLabel$ = this.isTeacher$.pipe(
    map((isTeacher) => {
      const teacherLabel = this.translateService.instant(
        'overflow.teacher_options',
      );
      const studentLabel = this.translateService.instant(
        'overflow.student_options',
      );
      return isTeacher ? teacherLabel : studentLabel;
    }),
  );

  // expose import ref
  readonly btnIcon = ButtonPurpose.Icon;

  readonly destroy$ = new Subject();

  constructor(
    private readonly config: ReaderConfigStore,
    private readonly readerStore: ReaderStore,
    private readonly store: Store,
    private readonly titlecase: TitleCasePipe,
    private readonly translateService: TranslateService,
    private readonly liveAnnouncer: LiveAnnouncer,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  /** menu open state */
  openMenu(): void {
    this.menuOpen.next(true);
  }

  closeMenu(): void {
    this.menuOpen.next(false);
  }

  /** menu events */
  onAttach(): void {
    asyncScheduler.schedule(() => {
      const teacherItems = this.items.filter(({ item }) =>
        isOverflowMenuAction(String(item.value)),
      );
      const levelContentItems = this.items.filter(
        ({ item }) =>
          isAllowedLevel(String(item.value)) ||
          isLexileLevel(String(item.value)),
      );

      if (teacherItems?.length) {
        this.teacherOptionsKeyManager = new FocusKeyManager(teacherItems);
        this.teacherOptionsKeyManager.setFirstItemActive();
      }

      if (levelContentItems?.length) {
        this.levelContentKeyManager = new FocusKeyManager(
          levelContentItems,
        ).withWrap();
      }
    });
  }

  onDetach(): void {
    this.closeMenu();
  }

  menuClickOutside(_event: MouseEvent): void {
    this.closeMenu();
  }

  teacherOptionsArrowKeyNav(event: KeyboardEvent): void {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      const currentElement = event.target as HTMLElement;
      const ul = currentElement.closest('ul');
      if (ul) {
        const buttons = Array.from(ul.querySelectorAll('button'));
        const currentIndex = buttons.indexOf(currentElement as HTMLButtonElement);
        const direction = event.key === 'ArrowDown' ? 1 : -1;
        const nextIndex = (currentIndex + direction + buttons.length) % buttons.length;
        (buttons[nextIndex]).focus();
      }
    }
  }

  levelContentKeyDown(event: KeyboardEvent): void {
    const { key, shiftKey } = event;

    const tab = key === 'Tab';
    if (shiftKey && tab) {
      event.preventDefault();
      this.teacherOptionsKeyManager.setLastItemActive();
    } else {
      this.levelContentKeyManager.onKeydown(event);
    }
  }

  levelOptionsFocus(_event: any): void {
    const activeItem = this.levelContentKeyManager?.activeItem;

    activeItem
      ? this.levelContentKeyManager?.setActiveItem(activeItem)
      : this.levelContentKeyManager?.setFirstItemActive();
  }

  /** menu actions */
  optionSelected(option: OverflowMenuOption<OverflowMenuActions>): void {
    if (option.value === 'teacher-content') {
      const stateToggle = option.active ? 'collapsed' : 'expanded';
      const announceToggleMessage = this.translateService.instant(
        `overflow.teacher_${stateToggle}_announce`,
      );
      void this.liveAnnouncer.announce(announceToggleMessage, 'polite');
    }
    option.action?.();
    this.closeMenu();
  }

  contentLevelSelected(level: AllowedLevel): void {
    const action = readerActions.changeContentLevel({ level });
    this.readerStore.dispatch(action);
    this.closeMenu();
  }

  lexileLevelSelected(level: LexileLevel): void {
    this.readerStore.setSelectedLexileLevel(level);
    this.closeMenu();
  }

  trackByOptionValue(_i: number, option: OverflowMenuOption<unknown>): unknown {
    return option.value;
  }

  /** teacher options */
  private getTeacherOptions$(): Observable<
  Array<OverflowMenuOption<OverflowMenuActions>>
  > {
    let defaultTeacherOptions = this.mapTeacherOtpions();
    const defaultTeacherOptions$ = combineLatest([
      this.isTeacherAndReview$,
      this.teacherContentEnabled$,
      this.readerStore.isFixedLayout$,
    ]).pipe(
      map(([isTeacherAndReview, teacherContent, isFixedLayout]) => {
        const tcIndex = defaultTeacherOptions.findIndex(
          ({ value }) => value === this.teacherContent,
        );

        if (tcIndex > -1) {
          defaultTeacherOptions = this.updateOptionActive(
            tcIndex,
            defaultTeacherOptions,
            teacherContent as boolean,
          );
        }

        if (isFixedLayout) {
          defaultTeacherOptions = defaultTeacherOptions.filter(
            ({ value }) =>
              value !== this.clearWidgetDataAll &&
              value !== this.clearWidgetDataPg &&
              value !== this.teacherContent,
          );
        }

        return isTeacherAndReview
          ? defaultTeacherOptions.filter(
            ({ value }) => value === this.teacherContent,
          )
          : defaultTeacherOptions;
      }),
    );

    return defaultTeacherOptions$;
  }

  private mapTeacherOtpions(): Array<OverflowMenuOption<OverflowMenuActions>> {
    const clearHighlightsOptions = this.getClearHighlightsOptions();
    const clearWidgetDataOptions = this.getClearWidgetDataOptions();
    const teacherContentOption = this.getTeacherContentOption();

    const options: Array<OverflowMenuOption<OverflowMenuActions>> = [
      ...clearHighlightsOptions,
      ...clearWidgetDataOptions,
      teacherContentOption,
    ];

    return options;
  }

  private getClearHighlightsOptions(): Array<
  OverflowMenuOption<OverflowMenuActions>
  > {
    const vvClearHighlightsAll = this.translateService.instant(
      'overflow.highlights.clear_all',
    );
    const vvClearHighlightsPg = this.translateService.instant(
      'overflow.highlights.clear_pg',
    );

    const optionAll: OverflowMenuOption<OverflowMenuActions> = {
      value: 'clear-highlights-all',
      viewValue: vvClearHighlightsAll,
      action: () => {
        const action = toolbarActions.confirmDeleteAllAnnotations();
        this.readerStore.dispatch(action);
      },
    };

    const optionPg: OverflowMenuOption<OverflowMenuActions> = {
      value: 'clear-highlights-pg',
      viewValue: vvClearHighlightsPg,
      action: () => {
        const action = toolbarActions.confirmDeletePageAnnotations();
        this.readerStore.dispatch(action);
      },
    };

    return [optionAll, optionPg];
  }

  private getClearWidgetDataOptions(): Array<
  OverflowMenuOption<OverflowMenuActions>
  > {
    const vvClearWidgetDataAll = this.translateService.instant(
      'overflow.clear_widget_data',
    );
    const vvClearWidgetDataPg = this.translateService.instant(
      'overflow.clear_widget_data_exhibit',
    );

    const optionAll: OverflowMenuOption<OverflowMenuActions> = {
      value: this.clearWidgetDataAll,
      viewValue: vvClearWidgetDataAll,
      action: () => {
        this.store.dispatch(
          widgetsActions.resetActivitiesConfirm({ scope: 'all' }),
        );
      },
    };

    const optionPg: OverflowMenuOption<OverflowMenuActions> = {
      value: this.clearWidgetDataPg,
      viewValue: vvClearWidgetDataPg,
      action: () => {
        this.store.dispatch(
          widgetsActions.resetActivitiesConfirm({ scope: 'exhibit' }),
        );
      },
    };

    return [optionAll, optionPg];
  }

  private getTeacherContentOption(): OverflowMenuOption<OverflowMenuActions> {
    const vvTeacherContent = this.translateService.instant(
      'overflow.teacher_content',
    );

    const option: OverflowMenuOption<OverflowMenuActions> = {
      value: this.teacherContent,
      viewValue: vvTeacherContent,
      action: () => {
        this.readerStore.toggleTeacherContentEnabled();
      },
    };

    return option;
  }

  /** leveled content */
  private getContentLevelOtpions$(): Observable<any> {
    const { levelDefault$, displayLevels$, isTeacher$ } = this.config;
    const { renditions$ } = this.readerStore;

    const epubFilteredDisplayLevels$ = combineLatest([
      displayLevels$,
      renditions$,
    ]).pipe(
      map(([display, renditions]) =>
        display?.filter((dl) => renditions.includes(dl)),
      ),
      map((levels) => (levels?.length === 0 ? null : levels)),
    );

    const contentLevels$ = combineLatest([
      levelDefault$,
      epubFilteredDisplayLevels$,
      isTeacher$,
    ]).pipe(
      map(([defaultLevel, levels, teacher]) =>
        this.coerceDefaultLevelOption(
          defaultLevel as AllowedLevel,
          levels as AllowedLevel[],
          teacher,
        ),
      ),
    );

    const activeContentLevel$ = combineLatest([
      this.readerStore.selectedLevel$,
      this.readerStore.parserOptions$,
    ]).pipe(
      map(
        ([selected, { defaultLevel }]) =>
          selected ?? (defaultLevel as AllowedLevel),
      ),
    );

    const levelContentOptions$ = combineLatest([
      contentLevels$.pipe(
        map((levels) =>
          levels?.map((level) => this.mapLevelContentToOption(level)),
        ),
      ),
      activeContentLevel$,
    ]).pipe(
      map(([options, activeValue]) =>
        options?.map((option) => ({
          ...option,
          active: option.value === activeValue,
        })),
      ),
    );

    return levelContentOptions$;
  }

  private mapLevelContentToOption(
    level: AllowedLevel,
  ): OverflowMenuOption<AllowedLevel> {
    const value = level;
    const viewValue = this.titlecase.transform(level.replace(/_/g, ' '));

    const option: OverflowMenuOption<AllowedLevel> = { value, viewValue };
    return option;
  }

  private coerceDefaultLevelOption(
    defaultLevel: AllowedLevel,
    levels: AllowedLevel[],
    isTeacher = false,
  ): AllowedLevel[] {
    if (levels !== null) {
      if (defaultLevel && !levels.includes(defaultLevel)) {
        levels = [defaultLevel, ...levels];
      }

      if (!isTeacher && levels?.length) {
        levels = levels.filter((level) => level === defaultLevel);
      }
    }

    return levels;
  }

  /** lexile levels */
  private getLexileLevelOptions$(): Observable<any> {
    const { lexileLevels$, activeLexileLevel$ } = this.readerStore;
    const { isTeacher$ } = this;

    const contextLexileLevels$ = combineLatest([
      isTeacher$,
      lexileLevels$,
    ]).pipe(map(([teacher, levels]) => (teacher ? levels : [])));

    const lexileOptions$ = combineLatest([
      contextLexileLevels$,
      activeLexileLevel$,
    ]).pipe(
      map(([levels, activeLevel]) =>
        levels?.map((level) => ({
          ...this.mapLexileLevelToOption(level),
          active: level === activeLevel,
        })),
      ),
    );

    return lexileOptions$;
  }

  private mapLexileLevelToOption(
    level: LexileLevel,
  ): OverflowMenuOption<LexileLevelsOption> {
    const value = level;
    const viewValue = String(level).replace(/_/, ' - ');

    const option: OverflowMenuOption<LexileLevel> = { value, viewValue };
    return option;
  }

  /** helpers */
  private updateOptionActive<T>(
    i: number,
    options: Array<OverflowMenuOption<T>>,
    active: boolean,
  ): Array<OverflowMenuOption<T>> {
    let option = options[i];
    option = { ...option, active };
    options = Object.assign([...options], { [i]: option });
    return options;
  }
}
