import { ApiAnnotation, ApiSpine } from '@mhe/reader/models';
import { createSelector } from '@ngrx/store';

import { READER_STATE_KEY, ReaderState } from '../global-store.selectors';
import { ANNOTATIONS_STATE_KEY, AnnotationsState } from './annotations.state';

const getState = (state): AnnotationsState => {
  return state[READER_STATE_KEY][ANNOTATIONS_STATE_KEY];
};

export const getAnnotationById = createSelector(
  getState,
  (
    state: AnnotationsState,
    { id }: { id: string },
  ): ApiAnnotation | undefined => {
    for (const spineId in state.entitiesBySpineId) {
      // eslint-disable-next-line no-prototype-builtins
      if (state.entitiesBySpineId.hasOwnProperty(spineId)) {
        const annotations = state.entitiesBySpineId[spineId];
        if (id in annotations) {
          return annotations[id];
        }
      }
    }
  },
);

export const getAnnotationsProgress = createSelector(
  getState,
  (state: AnnotationsState) => ({
    loaded: state?.loaded,
  }),
);

export const getSpines = createSelector(getState, (state: AnnotationsState) => {
  return state.spines;
});

export const haveSpineItems = createSelector(
  getState,
  (state: AnnotationsState) => {
    const spineItems = state.spines.find(spine => Boolean(spine.spineItems));
    return spineItems;
  },
);

export const isSpineLoaded = createSelector<ReaderState, [AnnotationsState], any>(
  getState,
  ({ spines }: AnnotationsState) =>
    (spineId) =>
      spines.find((spine: ApiSpine) => spine.spineID === spineId)
        ?.spineDataRequested,
);

export const getSpineIds = createSelector(getSpines, (spines) => [
  ...new Set(spines.map((s) => s.spineID)),
]);

export const getEntitiesBySpineId = createSelector(
  getState,
  (state: AnnotationsState) => state.entitiesBySpineId,
);

function flatMap<T, U>(arr: T[], fn: (item: T) => U[]): U[] {
  return arr.reduce((acc: any[], item) => acc.concat(fn(item)), []);
}

export const getAnnotationsBySpineId = createSelector(
  getEntitiesBySpineId,
  (
    entitiesBySpineId: Record<string, Record<string, ApiAnnotation>>,
    { spineId }: any,
  ) => Object.values(entitiesBySpineId[spineId as string] || {}),
);

export const getAnnotations = createSelector(
  getSpineIds,
  getEntitiesBySpineId,
  (spineIds, entitiesBySpineId) =>
    flatMap(spineIds, (spineId: string) =>
      Object.values(entitiesBySpineId[spineId] || {}),
    ),
);

export const getAreAllSpinesLoaded = createSelector(
  getState,
  (state: AnnotationsState) => {
    const allSpineIds = [
      ...new Set(state.spines.map((spine) => spine.spineID)),
    ];
    const loadedSpineIds = new Set(Object.keys(state.entitiesBySpineId));
    return allSpineIds.every((spineId) => loadedSpineIds.has(spineId));
  },
);

export const annotationsExportStatus = createSelector(
  getState,
  (state: AnnotationsState) => state.isExporting,
);

export const getSelectedAnnotation = createSelector(
  getState,
  (state: AnnotationsState) => state?.selectedAnnotation,
);
