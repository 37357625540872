import { DocTransform } from '@mhe/reader/models';
import { Injectable } from '@angular/core';
import { ExtendedComponentStore, logCatchError } from '@mhe/reader/common';
import { ofType } from '@ngrx/effects';
import {
  Observable,
  asyncScheduler,
  combineLatest,
  fromEvent,
  NEVER,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  observeOn,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { FixedPageService } from '../fixed-page.service';
import { EpubViewerRenderService } from './epub-viewer-render.service';
import { EpubViewerState, initialEpubViewerState } from './epub-viewer.state';
import { RenderEvents } from './models/render-events.model';
import { filterIframe } from './utils/filter-iframe.util';
import { renderDistinct } from './utils/render-distinct.util';
import * as actions from './epub-viewer.actions';

import {
  ReaderConfigStore,
  ReaderStore,
} from '@mhe/reader/components/reader/state';
import { EpubLibCFIService } from '@mhe/reader/features/annotation';
import {
  ToggleBoxActions,
  handleToggleBoxOnParent,
} from '@mhe/reader/components/epub-viewer/state/utils';
import { annouceSelectedAnnotationText } from '@mhe/reader/global-store/annotations/annotations.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class EpubViewerStore extends ExtendedComponentStore<
EpubViewerState,
actions.EpubViewerActions
> {
  private readonly zoomIncrement = 0.1;
  private readonly minZoomLevel = 1;
  private readonly maxZoomLevel = 4;
  private readonly autoAlbumModeThrehold = 1028; // pixels

  constructor(
    private readonly renderService: EpubViewerRenderService,
    private readonly fixedPageService: FixedPageService,
    private readonly readerConfigStore: ReaderConfigStore,
    private readonly readerStore: ReaderStore,
    private readonly epubCfiLib: EpubLibCFIService,
    private readonly window: Window,
    private readonly store: Store,
  ) {
    super(initialEpubViewerState);
  }

  /** selectors */
  readonly cloIframe$ = this.select(({ cloIframe }) => cloIframe).pipe(
    filterIframe,
  );

  readonly leftIframe$ = this.select(({ leftIframe }) => leftIframe).pipe(
    filterIframe,
  );

  readonly rightIframe$ = this.select(({ rightIframe }) => rightIframe).pipe(
    filterIframe,
  );

  readonly isDoubleSpread$ = this.select(
    ({ isDoubleSpread }) => isDoubleSpread,
  );

  readonly albumMode$ = this.select(({ albumMode }) => albumMode);
  readonly albumModeUserReq$ = this.select(
    ({ albumModeUserReq }) => albumModeUserReq,
  );

  public isAlbumModeForced$ = fromEvent(this.window, 'resize').pipe(
    debounceTime(50),
    startWith({}), // emit a value on subscribe
    map(() => this.window.innerWidth < this.autoAlbumModeThrehold),
  );

  readonly zoomLevel$ = this.select(({ zoomLevel }) => zoomLevel);
  readonly increaseEpubZoomEnabled$ = this.zoomLevel$.pipe(
    map((zoomLevel) => zoomLevel < this.maxZoomLevel),
  );

  readonly decreaseEpubZoomEnabled$ = this.zoomLevel$.pipe(
    map((zoomLevel) => zoomLevel > this.minZoomLevel),
  );

  readonly zoomMoveArrowsEnabled$ = this.select(
    ({ directionArrows }) => directionArrows,
  );

  readonly isFplSpine$ = this.select(({ isFplSpine }) => isFplSpine);

  readonly documentRequestError$ = this.select(
    ({ documentRequestError }) => documentRequestError,
  );

  /** updaters */
  readonly setCloIframe = this.updater(
    (state, cloIframe: HTMLIFrameElement) => ({ ...state, cloIframe }),
  );

  readonly setLeftIframe = this.updater(
    (state, leftIframe: HTMLIFrameElement) => ({ ...state, leftIframe }),
  );

  readonly setRightIframe = this.updater(
    (state, rightIframe: HTMLIFrameElement) => ({
      ...state,
      rightIframe,
    }),
  );

  readonly setDoubleSpread = this.updater((state, isDoubleSpread: boolean) => ({
    ...state,
    isDoubleSpread,
  }));

  /**
   * Need to set the current albumMode state
   * as well as remember what the user requested.
   */
  readonly toggleAlbumMode = this.updater(
    (state, { userRequested }: { userRequested: boolean }) => ({
      ...state,
      albumMode: !state.albumMode,
      albumModeUserReq: userRequested
        ? !state.albumMode
        : state.albumModeUserReq,
    }),
  );

  readonly increaseZoomLevel = this.updater((state) => ({
    ...state,
    zoomLevel: Math.min(
      state.zoomLevel + this.zoomIncrement,
      this.maxZoomLevel,
    ),
  }));

  readonly decreaseZoomLevel = this.updater((state) => ({
    ...state,
    zoomLevel: Math.max(
      state.zoomLevel - this.zoomIncrement,
      this.minZoomLevel,
    ),
  }));

  readonly setArrowDirections = this.updater(
    (
      state,
      arrowStates: {
        upEnabled?: boolean
        downEnabled?: boolean
        leftEnabled?: boolean
        rightEnabled?: boolean
      },
    ) => ({
      ...state,
      directionArrows: { ...state.directionArrows, ...arrowStates },
    }),
  );

  readonly setIsFplSpine = this.updater((state, isFplSpine: boolean) => {
    return {
      ...state,
      isFplSpine,
    };
  });

  readonly setDocumentRequestError = this.updater(
    (state, documentRequestError: boolean) => {
      return {
        ...state,
        documentRequestError,
      };
    },
  );

  /** action effects */
  private readonly _renderSinglePane$ = this.effect(() =>
    this.actions$.pipe(
      ofType(actions.renderSinglePane),
      tap(() => this.fixedPageService.clearFixedPages()),
      // get clo iframe
      switchMap(({ book, spineItem, hash, setFocus }) =>
        this.cloIframe$.pipe(
          map((iframe) => ({
            book,
            spineItem,
            iframe,
            hash,
            setFocus,
            doc: null,
          })),
        ),
      ),
      renderDistinct((hash, setFocus, spineItem) =>
        this.scroll$({ hash, setFocus, spineItem }),
      ),
      switchMap(({ hash, setFocus, ...dt }) =>
        this.renderService.render$(dt as DocTransform, {
          ...this.defaultRenderEvents,
          complete: () =>
            this.scroll$({
              hash: hash as string,
              setFocus: setFocus as boolean,
              spineItem: dt.spineItem,
            }),
        }),
      ),
      tap(({ iframe }) => this.renderService.scrollListener$(iframe)),
      catchError((_, caught$) => {
        this.setDocumentRequestError(true);
        this.dispatch(actions.renderError());
        return caught$;
      }),
    ),
  );

  private readonly _renderDoublePane$ = this.effect(() =>
    this.actions$.pipe(
      ofType(actions.renderDoublePane),
      // TODO: renderDoubleDistinct - stop rerendring content, figure out how to apply `fixedPageService` on album mode toggle
      // get frames
      switchMap(({ book, doubleSpineItem }) =>
        combineLatest([this.leftIframe$, this.rightIframe$]).pipe(
          map(([leftIframe, rightIframe]) => ({
            book,
            doubleSpineItem,
            leftIframe,
            rightIframe,
          })),
        ),
      ),
      map(({ book, doubleSpineItem, leftIframe, rightIframe }) => {
        let left$: Observable<DocTransform> = NEVER;
        let right$: Observable<DocTransform> = NEVER;

        const { left, right } = doubleSpineItem;
        const render = { book, doc: null };

        this.fixedPageService.clearFixedPages();

        if (left) {
          left$ = this.renderService.render$(
            {
              ...render,
              spineItem: left,
              iframe: leftIframe,
            } as unknown as DocTransform,
            { ...this.defaultRenderEvents },
          );
        } else {
          this.renderService.clearDocument$(leftIframe);
        }
        if (right) {
          right$ = this.renderService.render$(
            {
              ...render,
              spineItem: right,
              iframe: rightIframe,
            } as unknown as DocTransform,
            { ...this.defaultRenderEvents },
          );
        } else {
          this.renderService.clearDocument$(rightIframe);
        }

        return { left$, right$ };
      }),
      switchMap(({ left$, right$ }) => combineLatest([left$, right$])),
      catchError((_, caught$) => {
        this.setDocumentRequestError(true);
        this.dispatch(actions.renderError());
        return caught$;
      }),
    ),
  );

  /** effects */
  private readonly scroll$ = this.effect(
    (value$: Observable<{ hash: string, setFocus: boolean, spineItem: any }>) =>
      value$.pipe(
        filter((scrollData) => Boolean(scrollData)),
        filter(({ hash }) => Boolean(hash)),
        observeOn(asyncScheduler, 300),
        withLatestFrom(
          this.cloIframe$,
          this.readerConfigStore.ribac$,
          this.readerConfigStore.cfi$,
          this.readerStore.isFixedLayout$,
        ),
        tap(
          ([
            { hash, setFocus, spineItem },
            iframe,
            ribac,
            cfi,
            isFixedLayout,
          ]) => {
            // default behavior is to setFocus on element for accessiblility
            // but this can be overwriten by integrators such as AWD
            if (setFocus === undefined) {
              setFocus = true;
            }

            // Current iframe html document
            const doc: Document = iframe.contentDocument as Document;

            // hotfix circuitbreaker for RIBAC scrolling and highlighting
            // https://jira.mheducation.com/browse/EPR-9070
            try {
              if (ribac && cfi?.includes(spineItem.id) && hash) {
                const range = this.epubCfiLib.getRangeFromCFI(cfi, doc);
                const containingElement = range.startContainer.parentElement;

                const body = doc.body;
                const bodyHeight = body.getBoundingClientRect().height;
                const viewportHeight = this.window.innerHeight;

                // if double-spread FPL || single-spread FPL || CREATE FPL spine item
                if (
                  this.fixedPageService.isDoubleSpread ||
                  isFixedLayout ||
                  this.fixedPageService.isFplSpine
                ) {
                  const fplMargin = viewportHeight + bodyHeight;
                  doc.documentElement.style.marginBottom = fplMargin + 'px';
                  doc.documentElement.style.overflow = 'scroll';
                } else {
                  // reflowable
                  const elementTop = containingElement?.getBoundingClientRect()
                    .top as number;
                  const distanceFromBottom = bodyHeight - elementTop;
                  const margin = viewportHeight - distanceFromBottom;

                  if (distanceFromBottom < viewportHeight) {
                    doc.body.style.marginBottom = margin + 'px';
                  }
                }

                containingElement?.scrollIntoView();

                return;
              }
              // eslint-disable-next-line @typescript-eslint/brace-style
            } catch (e) {
              // adding try/catch since this is a hotfix
              // on error, behavior will default to the generic non-ribac functionality
              console.error('RIBAC scroll or text selection failed', e);
            }

            // if the hash is an id, look for the element with that id with getElementById
            // getElementById handle better than querySelector in some cases like when the id have a '+' sign
            let scrollEl: HTMLElement = hash?.charAt(0)
              ? (doc.getElementById(hash.substring(1)) as HTMLElement)
              : (doc.querySelector(hash) as HTMLElement);

            if (scrollEl) {
              // special handling for figure tags... set focus on hyperlink child
              if (scrollEl.tagName === 'FIGURE') {
                const firstChild = scrollEl.querySelector('a');
                if (firstChild && firstChild.tagName === 'A') {
                  scrollEl = firstChild;
                }
              }

              // Validations
              handleToggleBoxOnParent(
                scrollEl,
                ToggleBoxActions.OPEN_TOGGLEBOX,
              );

              // Set the focus on the deep-linked item, but restore previous tab-order after onBlur
              scrollEl.scrollIntoView({ behavior: 'smooth' });
              this.store.dispatch(annouceSelectedAnnotationText());
              if (setFocus) {
                const prevTabIndex =
                  scrollEl.getAttribute('tabindex') === null
                    ? '-1'
                    : scrollEl.getAttribute('tabindex');
                scrollEl.setAttribute(
                  'data-prev-tabindex',
                  prevTabIndex as string,
                );
                scrollEl.setAttribute('tabindex', '0');
                setTimeout(() => {
                  scrollEl.focus();
                  scrollEl.addEventListener('blur', () => {
                    scrollEl.setAttribute(
                      'tabindex',
                      scrollEl.getAttribute('data-prev-tabindex') as string,
                    );
                    scrollEl.removeAttribute('data-prev-tabindex');
                  });
                }, 500);
              }
            }
          },
        ),
        logCatchError('hash scroll error'),
      ),
  );

  private readonly _onMoveZoomedEpub = this.effect(() =>
    this.actions$.pipe(
      ofType(actions.moveZoomedEpub),
      withLatestFrom(this.cloIframe$),
      tap(([{ direction }, cloIframe]) =>
        this.fixedPageService.moveZoomedEpub(cloIframe, direction),
      ),
      logCatchError('_onMoveZoomedEpub'),
    ),
  );

  private readonly _albumModeToggled$ = this.effect(() =>
    this.albumMode$.pipe(
      tap((albumMode: boolean) => this.fixedPageService.setAlbumMode(albumMode)),
    ),
  );

  private readonly _isDoubleSpreadToggled$ = this.effect(() =>
    this.isDoubleSpread$.pipe(
      tap((isDoubleSpread) =>
        this.fixedPageService.setIsDoubleSpread(isDoubleSpread as boolean),
      ),
      logCatchError('_isDoubleSpreadToggled$'),
    ),
  );

  private readonly _zoomLevelChanged$ = this.effect(() =>
    this.zoomLevel$.pipe(
      tap((zoomLevel) => this.fixedPageService.setZoomLevel(zoomLevel)),
      logCatchError('_zoomLevelChanged$'),
    ),
  );

  private readonly _zoomedEpubMoved$ = this.effect(() =>
    this.fixedPageService.directionArrowStates$.pipe(
      tap((arrowStates) => {
        this.setArrowDirections(arrowStates);
      }),
      logCatchError('_zoomedEpubMoved$'),
    ),
  );

  private readonly defaultRenderEvents: RenderEvents = {
    preRender: (document: HTMLDocument) => {
      this.dispatch(actions.preRenderComplete({ document }));
    },
    postRender: (dt: DocTransform) => {
      this.dispatch(actions.renderComplete({ dt }));
    },
  };
}
