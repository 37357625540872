import { OverlayRef } from '@angular/cdk/overlay';
import {
  Component,
  Inject,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewChecked,
  OnInit,
  inject,
  DestroyRef,
} from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ButtonPurpose } from '@mhe/ngx-shared';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map, take, tap } from 'rxjs/operators';
import {
  ApiAnnotation,
  TTSAudioContext,
  TTSRawTextOptions,
} from '@mhe/reader/models';
import {
  AnnotationsContextMenuConfig,
  HighlightColorWithTooltip,
  HighlightShape,
} from './annotations-context-menu.model';
import {
  ANNOTATION,
  ANNOTATIONS_CONTEXT_MENU_CONFIG,
  OVERLAY_REF,
} from './annotations-context-menu.tokens';
import {
  AnnotationsContextMenuStore,
  getHighlightColor,
} from './state/annotations-context-menu.store';
import * as annotationContextMenuActions from './state/annotations-context-menu.actions';
import { DeviceService } from '@mhe/reader/common';
import { ReaderStore } from './../reader/state/reader.store';
import { ReaderConfigStore } from '../reader/state';
import { v4 as uuid } from 'uuid';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'rdrx-annotations-context-menu',
  templateUrl: './annotations-context-menu.component.html',
  styleUrls: ['./annotations-context-menu.component.scss'],
})
export class AnnotationsContextMenuComponent implements OnInit, AfterViewChecked {
  TTSAudioContext = TTSAudioContext;
  ButtonPurpose = ButtonPurpose;
  onlyReadspeakerEnabled = false;
  @ViewChild('mheTextArea', { read: ElementRef }) textarea!: ElementRef;
  noteLength: number = 0;
  noteMaxCharacters: number = 3500;
  destroyRef = inject(DestroyRef);

  private readonly activeAnnotation$ = combineLatest([
    this.store.highlightShape$,
    this.store.highlightColor$,
  ]);

  private readonly mapAnnotationClass =
    (expected: string) => (souce$: Observable<[string, string]>) => {
      return souce$.pipe(
        map(([shape, color]) =>
          shape === expected && color ? `underline-container-${color}` : '',
        ),
      );
    };

  readonly defaultColor$ = this.store.defaultHighlightColor$;
  readonly defaultClass$ = this.activeAnnotation$.pipe(
    this.mapAnnotationClass('default'),
  );

  readonly underlineClass$ = this.activeAnnotation$.pipe(
    this.mapAnnotationClass('underline'),
  );

  readonly boxClass$ = this.activeAnnotation$.pipe(
    this.mapAnnotationClass('box'),
  );

  readonly circleClass$ = this.activeAnnotation$.pipe(
    this.mapAnnotationClass('circle'),
  );

  isTouchScreen = this.deviceService.isTouchScreen;

  colorOptionsWithTooltip$: Observable<HighlightColorWithTooltip[]> =
    combineLatest([this.store.highlightShape$, this.store.colorOptions$]).pipe(
      map(([highlightShape, colorOptions]) => {
        return colorOptions.map((highlightColor) => {
          const color = this.translateService.instant(
            `context.highlight.${highlightColor.toLowerCase()}`,
          );
          const tooltip =
            highlightColor === 'none'
              ? this.translateService.instant(
                getNoColorTranslationKey(highlightShape),
              )
              : this.translateService.instant('context.set_color', { color });
          return { highlightColor, tooltip };
        });
      }),
    );

  constructor(
    @Inject(OVERLAY_REF) private readonly overlayRef: OverlayRef,
    @Inject(ANNOTATION) readonly annotation: ApiAnnotation,
    @Inject(ANNOTATIONS_CONTEXT_MENU_CONFIG)
    readonly config: AnnotationsContextMenuConfig,
    public readonly store: AnnotationsContextMenuStore,
    public readonly readerConfigStore: ReaderConfigStore,
    public readonly readerStore: ReaderStore,
    public readonly translateService: TranslateService,
    public readonly deviceService: DeviceService,
    private readonly renderer: Renderer2,
    private readonly liveAnnouncer: LiveAnnouncer,
  ) {
    this.store.setAnnotation(annotation);
    if (annotation.note) {
      this.store.showOptionsPane('note');
    } else if (annotation.color && annotation.shape) {
      this.store.showOptionsPane('color');
    } else {
      // Hide any previously opened panes.
      // this is a requirement for readspeaker
      this.store.showOptionsPane(undefined);
    }

    combineLatest([
      this.readerStore.isFactoryMetadataAiAssistEnabled$,
      this.readerConfigStore.isAiAssistEnabled$,
      this.readerStore.isAiAssistReady$,
    ]).pipe(
      map(([isFactoryEnabled, isConfigEnabled, isReady]) => {
        this.config.isAiAssistOffered = isFactoryEnabled && isConfigEnabled && isReady;
      }),
    ).subscribe();

    this.onlyReadspeakerEnabled =
      !this.config.highlights &&
      !this.config.notes &&
      !this.config.placemarks &&
      !this.config.isAiAssistOffered &&
      this.config.readspeaker;
    if (this.onlyReadspeakerEnabled) {
      this.store.showOptionsPane('readspeaker');
      this.onLoadAudioText(false);
    }
  }

  ngOnInit(): void {
    combineLatest([this.store.note$, this.store.noteMaxCharacters$])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(([note, maxCharacters]) => {
          this.noteLength = note.length;
          this.noteMaxCharacters = maxCharacters;
        }),
        debounceTime(250),
        tap(() => {
          const announcement = [`${this.noteLength || 0}`,
            this.translateService.instant('context.add_note_charcter_out_of'),
            this.noteMaxCharacters,
            this.translateService.instant('context.add_note_charcters_remaining')].join(' ');

          void this.liveAnnouncer.announce(announcement, 'assertive');
        },
        ),
      )
      .subscribe();
  }

  ngAfterViewChecked(): void {
    if (this.textarea) {
      const textarea = this.textarea.nativeElement.querySelector('textarea');

      if (textarea) {
        const textareaId = textarea.id || `textarea-${uuid()}`;
        textarea.id = textareaId;
        this.renderer.setAttribute(
          textarea,
          'aria-label',
          this.translateService.instant('context.add_note_textarea'),
        );

        const existingLabel = this.textarea.nativeElement.querySelector(
          `label[for="${textarea.id}"]`,
        );

        if (!existingLabel) {
          const label = this.renderer.createElement('label');
          this.renderer.setProperty(
            label,
            'innerText',
            this.translateService.instant('context.add_note'),
          );
          this.renderer.setAttribute(
            label,
            'for',
            textarea.id || 'textarea-id',
          );

          this.renderer.insertBefore(textarea.parentNode, label, textarea);
        }
      }
    }
  }

  close(): void {
    this.overlayRef.detach();
  }

  highlight(highlightShape: HighlightShape): void {
    this.defaultColor$.pipe(take(1)).subscribe((defaultColor) => {
      this.store.showOptionsPane('color');
      this.store.setHighlightShape(highlightShape);
      const highlightColor = getHighlightColor(defaultColor);
      this.store.setHighlightColor(highlightColor);
    });
  }

  placemark(): void {
    this.store.showOptionsPane(undefined);
    this.store.togglePlacemark();
  }

  noteChanged(event: { value: string }): void {
    this.store.setNote(event.value);
  }

  onClickAiAssist(): void {
    // ensure the ai reader panel is opened
    this.readerStore.setAiAssistOpen(true);

    // send user selection to ai reader
    this.readerStore.setAnnotationDataForAiAssist({
      selectedText: this.annotation.text,
      selectedEpubCfi: this.annotation.cfi,
    });
  }

  isAiAssistPressed(): Observable<boolean> {
    return this.readerStore.isAiAssistOpen$;
  }

  isPlacemarkPressed(): Observable<boolean> {
    return this.store.placemarkText$.pipe(
      map(text => text !== null && text !== undefined && text.length > 0),
    );
  }

  isReadspeakerPressed(): Observable<boolean> {
    return this.store.visibleOptionsPane$.pipe(
      map(pane => pane === 'readspeaker'),
    );
  }

  isHighlightPressed(shape: HighlightShape): Observable<boolean> {
    return combineLatest([
      this.store.highlightShape$,
      this.store.highlightColor$,
    ]).pipe(
      map(([currentShape, currentColor]) =>
        currentShape === shape && currentColor !== 'none',
      ),
    );
  }

  onLoadAudioText(autoplay = true): void {
    const options: TTSRawTextOptions = {
      text: this.annotation.text,
      cfi: this.annotation.cfi,
      pageLanguage: undefined, // this annotation menu doesn't know the page's document language
      autoplay,
    };
    this.store.dispatch(
      annotationContextMenuActions.loadTextAudio({ options }),
    );
    this.store.showOptionsPane('readspeaker');
  }
}

function getNoColorTranslationKey(highlightShape: HighlightShape): string {
  switch (highlightShape) {
    case 'box':
      return 'context.remove_box';
    case 'circle':
      return 'context.remove_circle';
    case 'underline':
      return 'context.remove_underline';
    default:
      return 'context.remove_highlight';
  }
}
