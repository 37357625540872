import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InfoComponentComponent } from './info-component.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [InfoComponentComponent],
  exports: [InfoComponentComponent],
})
export class InfoComponentModule {}
